<template>
<!-- 体育产业 -->
    <div>
        <div class="posit-left">
            <lotterysales style="margin-bottom:0.18rem;"></lotterysales>
            <onlinemall></onlinemall>
        </div>
        <div class="posit-right">
            <sportsenterprise style="margin-bottom:0.18rem;"></sportsenterprise>
            <sportsindustry></sportsindustry>
        </div>    
    </div>    
</template>

<script>

export default {
    components: {
        lotterysales: () => import('../charts/sportindustry/lotterysales.vue'),
        onlinemall: () => import('../charts/sportindustry/onlinemall.vue'),
        sportsenterprise: () => import('../charts/sportindustry/sportsenterprise.vue'),
        sportsindustry: () => import('../charts/sportindustry/sportsindustry.vue'),
    }
}
</script>

<style scoped>

</style>